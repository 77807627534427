<template>
  <div class="home-page">
    <carousel
      :carouselImg="carouselImg"
      :height="750"
      :minWidth="1200"
    ></carousel>
    <!-- <img
      v-if="carouselImg.length > 0"
      :src="carouselImg[0].icon"
      alt=""
      style="height: 750px; width: 100%"
    /> -->
    <group-member></group-member>
    <div class="IntroductionBanner">
      <div class="warpper">
        <div class="index-title">
          <h6>
            Company
            <span>profile</span>
          </h6>
          <p><span>—— 公司简介 ——</span></p>
        </div>
        <div class="profile-section" v-if="videoData">
          <div class="profile-video">
            <img src="/imgs/v.png" style="width: 100%;height: 271.69px" alt="">
            <!-- <video
              width="100%"
              height="271.69px"
              loop
              controls
              ref="video"
              poster="/imgs/video.png"
            >
              <source
                :src="videoData.servicePath + videoData.cover.split(',')[0]"
                type="video/mp4"
              />
              <source
                :src="videoData.servicePath + videoData.cover.split(',')[1]"
                type="video/webm"
              />
            </video> -->
          </div>
          <div
            class="profile-detail"
            @click="() => goThere('groupIntroduction')"
          >
            <!-- <p v-html="videoData.content" class="profile-content"></p> -->
            <h6 class="company-name">{{ videoData.name }}</h6>
            <div class="line"></div>
            <span class="biref">{{ videoData.brief }}</span>
          </div>
        </div>
      </div>
    </div>
    <div class="newsBanner">
      <div class="warpper">
        <div class="index-title">
          <h6>
            Company
            <span>NEWS</span>
          </h6>
          <p><span>—— 公司新闻 ——</span></p>
        </div>
        <div class="news-section">
          <div class="news-list">
            <div class="list-box">
              <div
                :class="[
                  'list-item',
                  currentNews && currentNews.id == item.id
                    ? 'list-item-actived'
                    : '',
                ]"
                @mouseenter="cutNews(item)"
                @click="checkNews(item)"
                v-for="(item, index) in news"
                :key="index"
              >
                <p class="item-name">{{ item.name }}</p>
                <p class="item-time">{{ item.createDate.split(" ")[0] }}</p>
              </div>
            </div>
            <div class="img-box" v-if="currentNews">
              <img :src="currentNews.servicePath + currentNews.cover" alt="" />
            </div>
            <!-- <div
              class="news-list-child"
              v-for="(item, index) in news"
              :key="index"
              @click="checkNews(item)"
            >
              <div class="news-info">
                <img :src="item.servicePath + item.cover" alt="" />
                <h6>{{ item.name }}</h6>
                <p></p>
              </div>
              <div class="time">
                <p>
                  {{ item.createDate.split(" ")[0].split("-")[0] }} /
                  <b
                    >{{ item.createDate.split(" ")[0].split("-")[1] }}-{{
                      item.createDate.split(" ")[0].split("-")[2]
                    }}</b
                  >
                </p>
              </div>
            </div> -->
          </div>
          <a class="more" @click="() => goThere('companyNews')">more</a>
        </div>
      </div>
    </div>
    <div class="memberBanner">
      <div class="warpper">
        <div class="index-title">
          <h6>
            GROUP
            <span>member</span>
          </h6>
          <p><span>—— 品牌产品 ——</span></p>
        </div>
        <div class="member-section">
          <ul style="overflow: hidden">
            <li
              :class="current == index ? 'active' : ''"
              @mouseenter="enter(index)"
              v-for="(item, index) in memberData"
              :key="index"
			   v-if="item.name != '大数据'"
            >
              <div class="title-img">
                <img :src="item.servicePath + item.icon" alt="" />
                <div class="shade">
                  <h6><span>+</span>{{ item.name }}</h6>
                </div>
              </div>
              <div class="member-info">
                <div class="member-info-detail">
                  <h6>{{ item.name }}</h6>
                  <span>{{ item.tips.split("/")[0] }}</span>
                  <p>
                    {{ item.tips.split("/")[1] }}
                  </p>
                  <a class="turn" @click="goProduct(item)"></a>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="honorBanner">
      <div class="warpper">
        <div class="index-title">
          <h6>
            HONOR
            <span>QUALIFICATIONS</span>
          </h6>
          <p><span>—— 荣誉资质 ——</span></p>
        </div>
        <div class="honor-section">
          <div class="run-one">
            <div class="run-one-child" v-if="currentHonorImg">
              <ul
                :style="{
                  left: runImgOneUl + 'px',
                  width: runImgOneWidth + 'px',
                }"
                ref="imgBox"
              >
                <li
                  @click="
                    () => checkHonorDetail(honorImg[length - 2], honorImg)
                  "
                >
                  <img
                    :src="
                      honorImg[length - 2].servicePath +
                      honorImg[length - 2].cover
                    "
                    class="small"
                  />
                </li>
                <li
                  @click="
                    () => checkHonorDetail(honorImg[length - 1], honorImg)
                  "
                >
                  <img
                    :src="
                      honorImg[length - 1].servicePath +
                      honorImg[length - 1].cover
                    "
                    class="small"
                  />
                </li>
                <li
                  @click="() => checkHonorDetail(item, honorImg)"
                  v-for="(item, index) in honorImg"
                  :key="index"
                  ref="item"
                >
                  <img
                    :src="item.servicePath + item.cover"
                    :class="[currentHonorImg.id == item.id ? 'big' : 'small']"
                  />
                </li>
                <li @click="() => checkHonorDetail(honorImg[0], honorImg)">
                  <img
                    :src="honorImg[0].servicePath + honorImg[0].cover"
                    class="small"
                  />
                </li>
                <li @click="() => checkHonorDetail(honorImg[1], honorImg)">
                  <img
                    :src="honorImg[1].servicePath + honorImg[1].cover"
                    class="small"
                  />
                </li>
              </ul>
            </div>
          </div>
          <!-- <div class="run-two">
            <div class="run-two-child">
              <ul
                :style="{
                  left: runImgOneUl2 + 'px',
                  width: runImgOneWidth2 + 'px',
                }"
              >
                <li
                  @click="() => checkHonorDetail(item, honorImg2)"
                  v-for="(item, index) in honorImg2"
                  :key="index"
                >
                  <img :src="item.servicePath + item.cover" />
                </li>
              </ul>
            </div>
          </div> -->
          <a class="more">more</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import carousel from "@components/carousel";
import groupMember from "@components/groupMember";
import CU from "@common/util";
export default {
  name: "",
  components: {
    carousel,
    groupMember,
  },
  data() {
    return {
      carouselImg: [],
      honorImg: [],
      honorImg2: [],
      runImgOneUl: 0,
      runImgOneUl2: 0,
      runImgOneWidth: 4000,
      runImgOneWidth2: 4000,
      current: 0,
      news: [],
      memberData: [],
      videoData: null,
      currentArr: [],
      currentNews: null,

      currentHonorImg: null,
      length: 0,
    };
  },
  computed: {
    menuData() {
      return this.$store.state.permission.menu;
    },
  },
  methods: {
    enter(item) {
      this.current = item;
    },
    runImgOne() {
      let temp = this.$refs.imgBox;
      let num = 0;
      let timer = setInterval(() => {
        num++;
        if (num == this.length) {
          num = 0
          temp.style.transition = 'none'
          temp.style.left = `${759.5 / 4 + 26}px`
          this.currentHonorImg = this.honorImg[num];
          setTimeout(() => {
            temp.style.left = '0px'
            temp.style.transition = 'all 2.5s'
            this.currentHonorImg = this.honorImg[num];
          }, 10)
          return
        } else {
          temp.style.transition = 'all 2.5s'
        }
        this.currentHonorImg = this.honorImg[num];
        if (this.runImgOneUl == 1200 - this.runImgOneWidth) {
          this.runImgOneUl = 0;
          return;
        }
        let oNum = -(759.5 / 4 + 26) * (num % this.honorImg.length);
        if (1200 - oNum > this.runImgOneWidth) {
          this.runImgOneUl = 1200 - this.runImgOneWidth;
          return;
        }
        this.runImgOneUl = oNum;
      }, 4000);
      this.$once("hook:beforeDestroy", () => {
        clearInterval(timer);
      });
    },
    runImgTwo() {
      let num = 0;
      let timer = setInterval(() => {
        num++;
        if (this.runImgOneUl2 == 1200 - this.runImgOneWidth2) {
          num = 0;
          this.runImgOneUl2 = 0;
          return;
        }
        let oNum = -172 * (num % this.honorImg2.length);
        if (1200 - oNum > this.runImgOneWidth2) {
          this.runImgOneUl2 = 1200 - this.runImgOneWidth2;
          return;
        }
        this.runImgOneUl2 = oNum;
      }, 4000);
      this.$once("hook:beforeDestroy", () => {
        clearInterval(timer);
      });
    },
    getHonors() {
      this.$post("websitnews/grouplist", {
        type: 1,
        keyword: "honoraryCertificate",
      }).then((res) => {
        this.honorImg = res;
        this.length = res.length;
        this.currentHonorImg = res[0];
        this.$nextTick(() => {
          this.runImgOne();
          this.runImgOneWidth = (this.honorImg.length + 4) * 243;
        });
      });
    },
    getHonors2() {
      this.$post("websitnews/grouplist", {
        type: 2,
        keyword: "honoraryCertificate",
      }).then((res) => {
        this.honorImg2 = res;
        this.$nextTick(() => {
          // this.runImgTwo();
          this.runImgOneWidth2 = this.honorImg2.length * 172;
        });
      });
    },
    checkHonorDetail(item, list) {
      this.$router.push({
        name: "honorQualification",
        params: {
          info: item,
          list,
        },
      });
    },
    getNews() {
      this.$post(this.$api.NEWS_INFO.LIST, {
        pageNo: 1,
        pageSize: 5,
        isRecommended: 1,
      }).then((res) => {
        this.news = res.list;
        this.currentNews = res.list[0];
      });
    },
    cutNews(news) {
      this.currentNews = news;
    },
    checkNews(item) {
      this.$store.commit(
        "setSubMenu",
        CU.getData("companyNews", this.menuData)
      );
      this.$store.commit("setCurrentRoute", "companyNews");
      this.$router.push({
        name: "newsInfo",
        query: {
          id: item.id,
          item,
          key: "companyNews",
        },
        params: {
          item,
        },
      });
    },
    goThere(router) {
      this.$router.push({ name: router });
    },
    getMember() {
      this.$post("websitemenu/menulist", {
        menuName: "品牌产品",
      }).then((res) => {
        this.memberData = res;
      });
    },
    getVideo() {
      this.$post("websitenews/list/companyInfo", {
        pageNo: 1,
        pageSize: 10,
      }).then((res) => {
        this.videoData = res.list[0];
      });
    },
    goProduct(item) {
      let currentArr = this.menuData[4].childList;
      for (let obj of currentArr) {
        if (item.name == obj.name) {
          this.$router.push({ name: obj.router });
        }
      }
    },

    getCarouse() {
      this.$post(this.$api.IMG_MANAGE.LIST, {
        imgType: "5",
        type: "2",
      }).then((res) => {
        this.carouselImg = res.list;
      });
    },
  },
  mounted() {
    this.getHonors();
    this.getHonors2();
    this.getNews();
    this.getMember();
    this.getVideo();
    this.getCarouse();
  },
};
</script>

<style lang="less" scoped>
@import url("./index.less");
.more {
  display: block;
  width: 140px;
  height: 40px;
  line-height: 40px;
  border-radius: 40px;
  text-align: center;
  font-weight: bold;
  font-size: 14px;
  text-transform: uppercase;
  color: #ffffff;
}
</style>
