<template>
  <div class="outer">
    <div class="centerBanner">
      <div class="oInner">
        <div
          class="oInner-child"
          v-for="(item, index) in data"
          :key="index"
          @click="goThere(item)"
        >
		<img :src="item.servicePath + item.icon" alt="" />
          <div class="oInner-child-info">
            <div class="s01">
              {{ item.name }} <br />{{ item.tips.split("/")[0] }}
              <span>+</span>
            </div>
            <div class="s02">
              <h6>{{ item.name }}</h6>
              <p>
                {{ item.tips.split("/")[1] }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "",
  data() {
    return {
      data: [],
      currentName: "",
    };
  },
  computed: {
    menuData() {
      return this.$store.state.permission.menu;
    },
  },
  methods: {
    getList() {
      this.$post("websitemenu/menulist", {
        menuName: "集团成员",
      }).then((res) => {
		var newData = [];
		newData.push(res[0]);
		newData.push(res[1]);
		newData.push(res[2]);
		this.data = newData;
      });
    },
    goThere(item) {
      let currentArr = this.menuData[3].childList;
	  // console.log('currentArr',currentArr)
      for (let obj of currentArr) {
        if (item.name == obj.name) {
		// console.log('obj.router',obj.router)
          this.$router.push({ name: obj.router });
        }
      }
    },
  },
  mounted() {
    this.getList();
  },
};
</script>

<style lang="less" scoped>
@import url("./index.less");
</style>
